:root {
  /* Font Size */
  --fs-section: 14px;
  --pd-section: 20px 30px;
}

@media screen and (min-width: 576px) {
  :root {
    --fs-section: 15px;
    --pd-section: 40px 60px;
  }
}

@media screen and (min-width: 768px) {
  :root {
    --fs-section: 16px;
  }
}

@media screen and (min-width: 992px) {
  :root {
    --fs-section: 14.5px;
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --fs-section: 15px;
    --pd-section: 40px 100px;
  }
}

@media screen and (min-width: 1300px) {
  :root {
    --fs-section: 16px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.hidden {
  display: none !important;
}

/* ----- HEADER ----- */

/* MENU */
.header {
  width: 100%;
  height: 100vh;
  background-image: url("../img/videos/portada_1.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.menu {
  background-color: transparent;
  color: #2e2a62;
  height: 80px;
}

.sticky {
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 10;
}

.menu__logo {
  width: 40px;
}

.menu__container {
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1300px;
  height: 100%;
  margin: 0 auto;
}

.menu__links {
  height: 100%;
  transition: transform 0.5s;
  display: flex;
}

.menu__item {
  list-style: none;
  position: relative;
  height: 100%;
  --clip: polygon(0 0, 100% 0, 100% 0, 0 0);
  --transform: rotate(-90deg);
}

.menu__item:hover {
  --clip: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  --transform: rotate(0deg);
}

.menu__link {
  color: #2e2a62;
  text-decoration: none;
  padding: 0 10px;
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 14px;
}

.last__menu__link {
  font-weight: 600;
}

.menu__link:hover {
  font-weight: 600;
}

.menu__arrow {
  transform: var(--transform);
  transition: transform 0.3s;
  display: block;
  margin-left: 3px;
}

.menu__nesting {
  list-style: none;
  transition: clip-path 0.3s;
  clip-path: var(--clip);
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  background-color: #ffffff;
  z-index: 10;
}

.menu__link--inside {
  padding: 20px;
  object-fit: contain;
}

.img__inside,
.last__img__inside {
  margin: 0 auto;
}

.img__inside {
  width: 55%;
  max-width: 160px;
}

.last__img__inside {
  width: 50%;
  max-width: 150px;
  margin-top: -1.7rem;
}

.menu__hamburguer {
  height: 100%;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  display: none;
}

.menu__img {
  display: block;
  width: 36px;
}

.menu--space {
  width: 100%;
  visibility: hidden;
  margin-bottom: 6rem;
}

.menu--nesting-sticky {
  background-color: rgba(255, 255, 255) !important;
  border-radius: 5px;
}

@media screen and (min-width: 576px) {
  .img__inside,
  .last__img__inside {
    width: 60%;
  }
}

@media screen and (min-width: 768px) {
  .menu__link {
    font-size: 16px;
  }

  .img__inside,
  .last__img__inside {
    width: 90%;
  }

  .menu--space {
    margin-bottom: 5rem;
  }
}

@media screen and (min-width: 992px) {
  .img__inside,
  .last__img__inside {
    width: 100%;
  }
}

@media screen and (max-width: 665px) {
  .menu__hamburguer {
    display: flex;
  }

  .menu__item {
    --clip: 0;
    overflow: hidden;
    border-bottom: 1px dashed #7dc5e8;
  }

  .menu__item--active {
    --transform: rotate(0);
  }

  .menu__item--show {
    background-color: var(--background);
    color: #ffffff;
  }

  .menu__links {
    position: fixed;
    max-width: 350px;
    width: 100%;
    top: 80px;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    overflow-y: auto;
    display: grid;
    grid-auto-rows: max-content;
    transform: translateX(100%);
    z-index: 10;
  }

  .menu__links--show {
    transform: unset;
    width: 100%;
  }

  .menu__link {
    padding: 25px 0;
    padding-left: 30px;
    height: auto;
  }

  .menu__arrow {
    margin-left: auto;
    margin-right: 20px;
  }

  .menu__nesting {
    display: grid;
    position: unset;
    width: 100%;
    transform: translateY(0);
    height: 0;
    transition: height 0.3s;
  }

  .menu__link--inside {
    width: 95%;
    padding: 5px 0;
    margin-left: auto;
    border-left: 1px dashed #7dc5e8;
  }

  .last__img__inside {
    margin-top: 0;
  }
}

@media screen and (min-width: 992px) {
  .menu__nesting {
    background-color: transparent;
  }
}

/**************************************************************++*/

/* HEADER CONTENT */

.central__logo {
  width: 90%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 125px);
  position: relative;
}

.big__logo {
  width: 85%;
  margin-bottom: 2rem;
}

.icons__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #00cdf9;
  padding: 10px 0;
  color: #ffffff;
}

.socialMedia__container {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
}

.social__row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
}

.media__dot {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: 0.3s;
}

.media__dot:hover {
  transform: translateX(15%);
}

.hr__container hr {
  border: none;
  border-left: 2px solid #2e2a62;
  height: 90px;
  width: 1px;
  flex-grow: 1;
}

.media__icon {
  display: block;
  width: 25px;
}

@media screen and (min-width: 576px) {
  .big__logo {
    width: 60%;
  }
}

@media screen and (min-width: 768px) {
  .big__logo {
    width: 65%;
  }

  .central__logo {
    height: calc(100vh - 130px);
  }

  .media__icon {
    width: 30px;
  }
}

@media screen and (min-width: 992px) {
  .central__logo {
    height: calc(100vh - 120px);
  }

  .menu__logo {
    margin-left: 0.5rem;
  }

  .media__icon {
    width: 20px;
  }

  .icons__wrapper {
    display: none;
  }

  .socialMedia__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .big__logo {
    width: 50%;
    margin-bottom: 3.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .big__logo {
    width: 500px;
    margin-bottom: 5rem;
  }
}

/* ----- SECTION 1 ----- */

/* WE ARE */
.section1__container {
  width: 100%;
}

.s1__leftside,
.s1__rightside {
  width: 100%;
  margin-top: 3rem;
  padding: var(--pd-section);
}

.subtitle {
  font-size: 25px;
  color: #2e2a62;
  font-weight: 200;
}

.thin__subtitle {
  margin-top: 1.5rem;
  font-size: 40px;
  color: #00cdf9;
  font-weight: 200;
  line-height: 1.2;
}

.bold__subtitle {
  font-size: 40px;
  color: #2e2a62;
  font-weight: 600;
  line-height: 1.2;
}

.little__subtitle {
  margin-top: 1.5rem;
  font-size: 18px;
  font-weight: bold;
}

.paragraph {
  margin-top: 1.5rem;
  font-size: var(--fs-section);
  font-weight: 300;
  text-align: left;
}

.interactive__btnContainer {
  border: 2px solid #00cdf9;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.team__btnContainer {
  width: 180px;
  padding: 5px 15px;
  margin: 1rem auto;
}

.interactive__text {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}

.interactive__text:hover {
  transform: translateY(5%);
}

.pandteam {
  width: 45%;
  color: #2e2a62;
}

.pipe {
  width: 10%;
  font-weight: 100;
}

.integrants {
  width: 45%;
  color: #00cdf9;
}

.img__team__container {
  width: 100%;
  text-align: center;
}

.intern__team__container {
  width: auto;
  text-align: center;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.img__team {
  width: 100%;
  border-radius: 20px;
  margin: 2rem auto 0 auto;
}

.img__footer {
  margin-top: 1.5rem;
  line-height: 1.2;
  font-size: 16px;
  font-weight: 500;
  color: #2e2a62;
}

.blue__pandp {
  color: #00cdf9;
}

/*Team Slider*/

.swiper-team {
  margin: 2rem auto 0 auto;
}

.team__slide__cont {
  margin: 0.5rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.team__img__slider {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 65%;
  margin: 0 auto;
}

.img__person__top,
.img__team__top {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.img__person {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.team__img__slider:hover .img__person__top,
.intern__team__container:hover .img__team__top {
  display: inline;
}

.team__name {
  color: #2e2a62;
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px;
}

.team__job {
  color: #2e2a62;
  font-weight: 300;
  font-size: 12px;
}

@media screen and (min-width: 500px) {
  .team__img__slider {
    width: 200px;
  }
}

@media screen and (min-width: 576px) {
  .img__team {
    width: 90%;
  }

  .img__footer {
    width: 90%;
    margin: 1.5rem auto 0 auto;
  }

  .team__img__slider {
    width: 250px;
  }
}

@media screen and (min-width: 768px) {
  .img__team,
  .img__footer {
    width: 450px;
  }

  .s1__leftside,
  .s1__rightside {
    margin-top: 0;
  }
}

@media screen and (min-width: 992px) {
  .section1__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .s1__leftside,
  .s1__rightside {
    width: 50%;
  }

  .team__img__slider {
    min-width: 100px;
    max-width: 195px;
  }

  .img__team,
  .img__footer {
    min-width: auto;
    max-width: 400px;
  }

  .team__btnContainer {
    margin: 0rem auto;
  }

  .paragraph {
    word-spacing: 5px;
    width: 120%;
  }

  .team__img__slider {
    width: 165px;
  }
}

@media screen and (min-width: 1200px) {
  .img__team,
  .img__footer {
    min-width: auto;
    max-width: 500px;
  }

  .thin__subtitle,
  .bold__subtitle {
    font-size: 45px;
  }

  .team__img__slider {
    width: 175px;
  }
}

@media screen and (min-width: 1300px) {
  .s1__leftside,
  .s1__rightside {
    padding: 40px 110px;
  }

  .subtitle {
    font-size: 30px;
  }

  .thin__subtitle,
  .bold__subtitle {
    font-size: 52px;
  }
  .team__img__slider {
    width: 195px;
  }
}

@media screen and (min-width: 1900px) {
  .section1__container {
    width: 95%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 2500px) {
  .section1__container {
    width: 90%;
    margin: 0 auto;
  }
}

/* ----- SECTION 2 ----- */

/* P&PODCAST */

.section2__container {
  width: 100%;
  margin-top: 2rem;
}

.micro__container {
  background: rgb(100, 90, 206);
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.microImg__container {
  width: 70px;
  object-fit: contain;
  position: relative;
}

.img__microphone {
  width: 100%;
}

.little__dot {
  height: 15px;
  width: 15px;
  background-color: #00cdf9;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 60%;
}

.podcastdes__container {
  color: #ffffff;
}

.podcast__announce {
  line-height: 1.2;
  font-size: 20px;
  word-spacing: 3px;
}

.podcast__shedule {
  margin-top: 0.5rem;
  font-size: 14px;
}

.podcastmedia__container {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.podcast__spoty,
.podcast__yt {
  display: block;
  margin-right: 1rem;
}

.podcast__spoty {
  width: 28px;
}
.podcast__yt {
  width: 30px;
}

.podcast__title {
  color: #00cdf9;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
}

.podcast__info__container {
  margin-top: 3rem;
  padding: var(--pd-section);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

.podcast__text__container {
  margin-top: 2rem;
}

.podcast__paragraph {
  margin-top: 1rem;
  font-size: var(--fs-section);
  font-weight: 300;
  text-align: center;
}

.dania__img {
  border-radius: 50%;
  max-width: 200px;
}

@media screen and (min-width: 450px) {
  .microImg__container {
    width: 80px;
  }

  .podcast__announce {
    font-size: 22px;
  }

  .podcast__shedule {
    font-size: 16px;
  }
}

@media screen and (min-width: 576px) {
  .micro__container {
    justify-content: center;
  }

  .microImg__container {
    width: 90px;
    margin: 0 3rem;
  }

  .podcastdes__container {
    margin: 0 3rem;
  }

  .podcast__announce {
    font-size: 25px;
  }

  .podcast__shedule {
    font-size: 19px;
  }
}

@media screen and (min-width: 768px) {
  .microImg__container {
    width: 120px;
    margin: 0 4rem;
  }

  .podcastdes__container {
    margin: 0 4rem;
  }

  .dania__img {
    max-width: 250px;
  }
}

@media screen and (min-width: 992px) {
  .section2__container {
    padding: var(--fs-section);
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(100, 90, 206);
    background: linear-gradient(
      97deg,
      rgba(100, 90, 206, 1) 43%,
      rgba(208, 192, 255, 1) 98%,
      rgba(214, 198, 255, 1) 100%
    );
  }

  .micro__container {
    width: 520px;
    background: none;
    justify-content: space-around;
  }

  .microImg__container {
    margin: 0;
  }

  .podcastdes__container {
    margin: 0;
  }

  .podcast__info__container {
    width: 520px;
    margin: 0;
    padding: 0 25px;
    flex-direction: row;
    justify-content: space-around;
  }

  .podcast__title {
    font-size: 32px;
    text-align: left;
  }

  .podcast__paragraph {
    text-align: left;
    font-size: var(--fs-section);
    color: #ffffff;
  }

  .podcast__text__container {
    margin: 0 1rem 0 0;
  }

  .dania__img {
    max-width: 200px;
  }

  .little__dot {
    width: 20px;
    height: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .micro__container,
  .podcast__info__container {
    width: 560px;
  }
  .microImg__container {
    width: 170px;
    margin-top: -5rem;
  }

  .little__dot {
    width: 30px;
    height: 30px;
  }

  .podcast__announce {
    font-size: 28px;
  }

  .podcast__shedule {
    font-size: 22px;
  }
}

/* ----- SECTION 3 ----- */

/* PROJECTS */
.projects__container {
  background-color: #f3f8fb;
}

.slider__section__container {
  margin-top: 3rem;
  padding: var(--pd-section);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects__btnContainer {
  width: 190px;
  padding: 8px 15px;
  margin: 2.5rem auto;
}

.pandprojects {
  width: 50%;
}

.services {
  width: 50%;
  color: #00cdf9;
}

.slider__paragraph {
  margin-top: 1rem;
  font-size: var(--fs-section);
  font-weight: 300;
  text-align: center;
}

.slider__container {
  width: 100%;
  margin: 0.5rem 0 1rem 0;
  z-index: 1;
}

.swiper {
  width: 100%;
  height: 100%;
  z-index: 1;
}

@media screen and (min-width: 1900px) {
  .swiper-team {
    width: 75%;
  }
}

@media screen and (min-width: 2500px) {
  .swiper-team {
    width: 60%;
  }
}

.swiper-slide {
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-direction: column;
  object-fit: contain;
  margin-bottom: 2rem;
  z-index: 1;
}

.slimg__container {
  position: relative;
}

.slider__img {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  z-index: 1;
  cursor: pointer;
}

.slide__title {
  margin-top: 0.5rem;
  font-size: 15px;
  font-weight: 400;
}

.slide__branding {
  font-size: 10px;
  font-weight: 300;
}

.forArrow__container {
  width: 100%;
}

.arrow {
  display: none;
  cursor: pointer;
}

/*Upper Arrows*/
.upper__arrows__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.upper__arrow {
  width: 40px;
  height: 40px;
  margin: 0;
  cursor: pointer;
}

/*White Card*/
.project__card {
  display: none;
  cursor: pointer;
  border: 1px solid #2e2a62;
  position: absolute;
  width: 90%;
  height: 90%;
  margin: 0 auto;
  padding: 45px 20px;
  background-color: white;
  border-radius: 35px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.slimg__container:hover .project__card {
  display: block;
}

.project__title,
.project__paragraph {
  color: #2e2a62;
  text-align: center;
}

.project__title {
  font-size: 12px;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.project__paragraph {
  font-size: 8px;
  font-weight: 500;
  text-align: center;
}

.mt-card-t {
  margin-top: 1rem;
}

/* Services */
.masonry {
  transition: all 0.5s ease-in-out;
  column-gap: 8px;
  column-fill: initial;
}

.masonry .brick {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.service__label {
  background-color: #ffffff;
  color: #2e2a62;
  font-weight: 500;
  width: 70%;
  padding: 5px;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translate(-50%);
}

.masonry .brick img {
  width: 100%;
  border-radius: 15px;
  cursor: pointer;
}

@media screen and (min-width: 330px) {
  .project__paragraph {
    font-size: 11px;
  }
}

@media screen and (min-width: 400px) {
  .project__card {
    width: 280px;
    height: 260px;
    margin: 0 auto;
  }

  .project__paragraph {
    font-size: 11.5px;
  }

  .project__title {
    font-size: 16px;
  }
}

@media screen and (min-width: 576px) {
  .masonry {
    column-count: 2;
  }

  .service__label {
    width: 75%;
  }
}

@media screen and (min-width: 900px) {
  .masonry {
    column-count: 3;
  }
}

@media screen and (min-width: 992px) {
  .upper__arrows__container {
    display: none;
  }

  .forArrow__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .arrow {
    display: block;
    margin: -4.5rem 0.5rem 0 0.5rem;
    width: 20px;
    height: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .project__card {
    width: 90%;
    height: 90%;
  }
}

@media screen and (min-width: 1100px) {
  .project__paragraph {
    font-size: 12px;
  }

  .project__title {
    margin-top: 0;
  }

  .masonry {
    column-count: 4;
  }

  .service__label {
    width: 80%;
  }
}

@media screen and (min-width: 1300px) {
  .projects__container {
    padding: 40px 140px;
  }

  .project__card {
    width: 300px;
    height: 270px;
  }
}

@media screen and (min-width: 1900px) {
  .project__card {
    width: 400px;
    height: 370px;
  }

  .project__title {
    font-size: 20px;
  }

  .project__paragraph {
    font-size: 16px;
  }

  .arrow {
    width: 40px;
    height: 80px;
    margin: -4.5rem 1rem 0 1rem;
  }
}

@media screen and (min-width: 2500px) {
  .arrow {
    width: 50px;
    height: 100px;
    margin: -4.5rem 1.5rem 0 1.5rem;
  }
}

/* ----- SECTION 4 ----- */

/* WHO WE WORK */

.customers__paragraph {
  width: 100%;
  margin: 2rem auto 0 auto;
}

.m-img-cs {
  width: 241px;
  margin: 0.5rem auto;
}

@media screen and (min-width: 768px) {
  .customers__paragraph {
    max-width: 500px;
  }
}

/* ----- SECTION 5 ----- */

/* CONTACT US */
.contact__background {
  width: 100%;
  background-image: url("../img/videos/portada_1.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section5__container {
  padding: var(--pd-section);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact__subtitle {
  margin-top: 3rem;
  text-align: left;
}

.contact__media__container {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.contact__media__item {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: #000000;
}

.contact__media__icon {
  display: block;
  width: 25px;
  margin: 0 0.5rem 0 0;
}

.contact__text {
  font-size: var(--fs-section);
  font-weight: 300;
}

.number__container {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;
}

.step__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form__number {
  border: 2px solid #2e2a62;
  margin-bottom: 0.5rem;
  font-weight: 300;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step__text {
  font-weight: 600;
  color: #2e2a62;
}

.hr__contact {
  display: none;
  margin-top: -1.5rem;
  justify-content: center;
  flex: 1;
}

.hr__contact hr {
  border: none;
  border-bottom: 1px solid #2e2a62;
  height: 1px;
  width: 80%;
  margin: 0 auto;
}

.form__container {
  width: 100%;
  margin: 2.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.contact__input {
  width: 100%;
  margin-top: 1.5rem;
  padding: 15px 10px;
  border-radius: 25px;
  border: 1px solid #2e2a62;
  font-size: var(--fs-section);
  font-weight: 600;
}

.contact__input::placeholder {
  color: #b2b2b2;
}

.form__btn__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.next__btn,
.previous__btn,
.submit__btn {
  cursor: pointer;
  margin: 2.5rem 1rem 0 0;
  font-size: var(--fs-section);
  color: #ffffff;
  padding: 15px 25px;
  border-radius: 25px;
  border: none;
  transition: 0.3s;
}

.next__btn,
.submit__btn {
  background-color: #00cdf9;
}

.previous__btn {
  background-color: #2e2a62;
}

.next__btn:hover,
.previous__btn:hover,
.submit__btn:hover {
  transform: translateY(5%);
}

input.invalid {
  border: 1px solid #ff4d4f;
}

.input--error {
  color: #ff4d4f;
  font-size: 13px;
  margin-top: 1rem;
  display: none;
}

.tab--active {
  border: 2px solid #00cdf9;
  color: #00cdf9;
}

.tab-text--active {
  color: #00cdf9;
}

.tab {
  display: none;
}

/* Loading */
.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 20;
}

.loading__container {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--pd-section);
}

.loader {
  width: 120px;
  height: 75px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.loader span {
  font-size: var(--fs-section);
  margin: auto;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}

.ball {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  animation: bounce 0.5s alternate infinite;
}

.ball:nth-child(2) {
  animation-delay: 0.16s;
}

.ball:nth-child(3) {
  animation-delay: 0.32s;
}

@keyframes bounce {
  from {
    transform: scaleX(1.25);
  }

  to {
    transform: translateY(-50px) scaleX(1);
  }
}

@media screen and (min-width: 300px) {
  .number__container {
    display: flex;
  }
}

@media screen and (min-width: 475px) {
  .contact__media__container {
    flex-direction: row;
    align-items: center;
  }

  .contact__media__item {
    margin-right: 2rem;
  }

  .hr__contact {
    display: block;
  }
}

@media screen and (min-width: 576px) {
  .section5__container {
    align-items: flex-start;
  }

  #c-1::after {
    content: "\a";
    white-space: pre;
  }
}

@media screen and (min-width: 768px) {
  .form__number {
    font-size: 25px;
    width: 55px;
    height: 55px;
  }
}

@media screen and (min-width: 992px) {
  .section5__container {
    width: 900px;
  }

  .form__number {
    width: 60px;
    height: 60px;
  }
}

@media screen and (min-width: 1200px) {
  .section5__container {
    width: 1000px;
  }
}

@media screen and (min-width: 1300px) {
  .form__number {
    font-size: 30px;
    width: 65px;
    height: 65px;
  }

  .section5__container {
    width: 1150px;
  }
}

/* ----- FOOTER ----- */
.footer__container {
  width: 100%;
  height: 150px;
  background-color: #2e2a62;
  display: flex;
  align-items: center;
}

.footer__content {
  width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__anchor {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.footer__text {
  color: #ffffff;
  font-size: var(--fs-section);
  font-weight: 500;
}

.footer__anchor:hover,
.footer__text:hover {
  color: #d3d3d3;
}

.footer__logo {
  width: 45px;
}

@media screen and (min-width: 576px) {
  .footer__content {
    width: 80%;
  }
}

/* Sweet Alert Config */
.swal2-modal {
  border-radius: 10px !important;
}

.swal2-confirm {
  width: 115px !important;
  height: 35px !important;
}

.swal2-title {
  font-size: 16px !important;
}

.swal2-html-container {
  font-size: 14px !important;
}

@media screen and (min-width: 400px) {
  .swal2-modal {
    max-width: 25em !important;
  }
}
